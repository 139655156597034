import { SelectInput } from 'shared-library';
import React from 'react';
import { Column, Row } from '../Layout';
import { AmountToPay } from '../AmountToPay';
import { ReferenceIdTextInput } from './ReferenceIdTextInput';

const ClinicPayment = ({ transaction, setTransaction }) => {
  const method = [
    { value: 'cash', label: 'Cash' },
    { value: 'cheque', label: 'Cheque' },
    { value: 'credit', label: 'Credit' },
    { value: 'debit', label: 'Debit' },
  ];
  return (
    <>
      <Row>
        <Column>
          <SelectInput
            label="Method"
            options={method}
            defaultValue={method.filter((s) => s.value === transaction.paymentMethod)}
            onChange={(option) => setTransaction({ ...transaction, paymentMethod: option.value })}
          />
        </Column>
        <AmountToPay transaction={transaction} setTransaction={setTransaction} />
        <ReferenceIdTextInput transaction={transaction} setTransaction={setTransaction} />
      </Row>
    </>
  );
};

export default ClinicPayment;
